<!-- 选择渲染页面 -->
<template>
  <div></div>
</template>

<script>
export default {
  data() {
    return {};
  },

  components: {},

  computed: {},

  mounted() {
    let width = document.body.clientWidth;
    let router;
    if (width > 600) {
      router = "/homePage";
    } else {
      router = "/phoneIndex";
    }
    this.$router.push(router);
  },

  methods: {},
};
</script>
<style lang="less" scoped></style>
